<template>
  <div style="display: inline-block;vertical-align: middle;height: 100%;width: 100%">
    <v-chart className="chart" :option="option"/>
  </div>


</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {SunburstChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, {THEME_KEY} from "vue-echarts";
import {ref, defineComponent} from "vue";

use([
  CanvasRenderer,
  SunburstChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default defineComponent({
  name: "HelloWorld",
  props:{
    chartData:{
      required:true
    },
  },
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  setup() {
    const option = ref({
      title: {
        text: "",


      },
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c}"
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      series: {
        type: 'sunburst',
        data: [],
        radius: [60, '90%'],

        label: {
          show: true
        }
      }});

    return {option};
  },
  mounted() {
    this.option.series.data=this.chartData;
  },
  watch:{
    "chartData":{
      deep:true,
      handler(){
        this.option.series.data=this.chartData;
      }
    }
  }
});
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
